<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>所属门店：</p>
          <select @change="getEmpList" v-model="form.fenpeimendianId">
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>员工：</p>
          <select @change="getData(1)" v-model="form.userId">
            <option :value="null">全部</option>
            <option :value="item.id" v-for="item in EmpList" :key="item.id">{{item.xingming}}</option>
          </select>
        </li>
        <li>
          <p>时间：</p>
          <select v-model="form.Sdate" @change="addDate">
            <option v-for="item in dateList" :key="item.id" :value="item.id">{{item.name}}</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
        <table>
          <thead>
            <tr>
              <td>员工</td>
              <td style="text-align:center">底薪</td>
              <td style="text-align:center">提成</td>
              <td style="text-align:center">总额</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dataList" :key="item.id">
              <td>{{item.xingming}}</td>
              <td style="text-align:center">{{item.dixin}}</td>
              <td style="text-align:center">{{resetTC(item.tc)}}</td>
              <td style="text-align:center"><strong><span class="err">{{resetTC(item.dixin+item.tc)}}</span></strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {deepCopy} from '@/utils/util'
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'wages',
  data() {
    return {
      dateList:[
        {
          id:5,
          name:'本月',
          startDate:moment().startOf("M").format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:6,
          name:'上月',
          startDate:moment().add(-1,"M").startOf("M").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"M").endOf("M").format("YYYY-MM-DD"),
        },

      ],
      dataList:[],
      storeList:[],
      EmpList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        Sdate:5,
        startDate:moment().startOf("M").format("YYYY-MM-DD"),
        endDate:null,
      },
    }
  },
  created() {
    this.getStore()
  },
  mounted() {},
  methods: {
    resetTC(tc){
      if(tc) return tc.toFixed(2)
      else return '0.00'
    },
    addDate(){
      let d=this.dateList.find(e=>e.id==this.form.Sdate)
      this.form.startDate=d.startDate
      this.form.endDate=d.endDate
      this.getData()
      
    },
    getData(){
      axios.post('/record/tichengJL',this.form).then((res) => {
        if(res.code===5000){
          let resData=deepCopy(this.EmpList)
          resData.forEach(e=>{
            let arr=res.data.filter(a=>a.userId==e.id)
            e.tc=0
            if(arr.length){
              arr.forEach(b=>{
                e.tc+=b.tc
              })
            }
          })
          let orgData=res.data.filter(e=>{
            let c=this.EmpList.find(a=>a.id==e.userId)
            if(!c){
              e.xingming=e.xingming+'（已删）'
              e.dixin=0
              return true
            }
            return false
          })
          this.dataList=[...resData,...orgData]
        }else this.$Mesg({content: '获取数据失败！',errOrSuc: 'err'})
      })
    },
    getStore(){
      if(this.$store.state.User.jibieId==0){
        axios.post('/store/list').then((res) => {
          if(res.code===5000){
            this.storeList=res.data
            this.getEmpList()
          }else{
            this.$Mesg({content: res.msg,errOrSuc: 'err'})
          }
        })
      }else this.getEmpList()
    },
    getEmpList(){
      axios.post('/emp/list',{fenpeimendianId:this.form.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.EmpList=res.data.map(e=>{
            return{
              id:e.id,
              xingming:e.xingming,
              dixin:e.dixin,
            }
          })
          this.form.userId=null
          this.getData()
        }else{this.$Mesg({content: res.msg,errOrSuc: 'err'})}
      })
    },
    
  },
}
</script>
